import React, { RefObject, JSX } from 'react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { PaymentsMethodMfeExposedApi } from '@ftdr/payment-method-micro-frontend'
import { PaymentMFE } from 'src/components/payment-mfe'
import { api } from 'src/utils/api'

const Payments = ({
  error,
  submitPaymentRef,
}: {
  error: string
  submitPaymentRef: RefObject<PaymentsMethodMfeExposedApi>
}): JSX.Element | null => {
  const isEnvStaging = api.config.ENV === 'staging'

  return !isEnvStaging ? (
    <div>
      <div className="max-w-md">
        <PaymentMFE ref={submitPaymentRef} />
      </div>
      {!!error && (
        <Text
          id="outright-payment-subform-error"
          className="font-bold mt-2"
          variant="helper-text"
          color="error"
        >
          {error}
        </Text>
      )}
    </div>
  ) : null
}

export default Payments
