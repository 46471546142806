import React, { useEffect } from 'react'
import { App, SupportedLanguages } from '@ftdr/blueprint-components-react'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { AuthProvider } from '@ftdr/use-auth'
import { getIdentityProvidersConfig } from './identity-providers-config'
import { api } from 'src/utils/api'
import { AppliancePortalApp } from './appliance-portal-app'
import ThemeSelector from '../theme-selector/theme-manual'
import { ScrolledProvider } from 'src/hooks/use-isScrolled-context'

const lang = navigator.language?.replace('-', '_') as SupportedLanguages
const textTemplatesByLanguage = {
  en_US: textTemplatesEnglish,
}
const appSettings = {
  language: textTemplatesByLanguage[lang] ? lang : 'en_US',
  textTemplatesByLanguage,
}

export const AppliancePortalAuth: React.FC = () => {
  useEffect(() => {
    //Initialize Tealium
    window.utag_cfg_ovrd = { noview: true }
    const url = `https://tags.tiqcdn.com/utag/americanhomeshield/ahs-appliances.ahs.com/${api.config.TAELIUM_ENV}/utag.js`
    const script = document.createElement('script')
    Object.assign(script, {
      src: url,
      async: true,
    })
    const container = document.getElementsByTagName('script')[0]
    container?.parentNode?.insertBefore(script, container)
  }, [])

  return (
    <ThemeSelector>
      <div className={`pt-76px lg-pt-96px bg-picket-white min-h-full pb-24`}>
        <App appSettings={appSettings}>
          <AuthProvider serviceConfigs={getIdentityProvidersConfig()}>
            <ScrolledProvider>
              <AppliancePortalApp />
            </ScrolledProvider>
          </AuthProvider>
        </App>
      </div>
    </ThemeSelector>
  )
}
