import React, { JSX } from 'react'
import Actions from './actions'
import Banners from './banners/banners'
import Cache from './cache'
import Refresh from './refresh'

export const generateForm = (adminFunction: string): JSX.Element | null => {
  if (!adminFunction) return null
  if (
    adminFunction === 'blocklist' ||
    adminFunction === 'special_prices' ||
    adminFunction === 'no_delivery' ||
    adminFunction === 'preferred'
  ) {
    return <Actions />
  } else if (adminFunction === 'caches') {
    return <Cache />
  } else if (adminFunction === 'refresh_catalog') {
    return <Refresh />
  } else if (adminFunction === 'banners') {
    return <Banners />
  } else {
    return null
  }
}
