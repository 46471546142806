import { useEffect } from 'react'
import { ChatType } from 'src/components/appliance-portal-routes'
import { api } from 'src/utils/api'

export const useAmazonChat = (type: ChatType) => {
  const quiqLoadHandler = async () => {
    const chat = window.amazon_connect
    if (typeof chat !== 'function') {
      console.error('Amzon Chat Error: window.amazon_connect is not a function')
    }
  }

  useEffect(() => {
    const isDevOrTest = api.config.ENV !== 'production'
    const src =
      'https://dend6g4sigg57.cloudfront.net/amazon-connect-chat-interface-client.js'

    const id =
      api.config.TENANT === 'ahs'
        ? api.config.AMAZON_CHAT_AHS_CLIENT_ID
        : api.config.AMAZON_CHAT_HSA_CLIENT_ID //eab68b26-bd1a-4450-ad1d-a9c5952872a1
    const snippedId =
      api.config.TENANT === 'ahs'
        ? api.config.AMAZON_CHAT_AHS_SNIPPED_ID
        : api.config.AMAZON_CHAT_HSA_SNIPPED_ID

    if (!isDevOrTest) return
    if (type === 'quiq' || !type) return
    const chatHead = document.createElement('script')

    chatHead.src = src
    chatHead.id = id
    chatHead.async = true
    window.amazon_connect =
      window.amazon_connect ||
      function () {
        ;(window.amazon_connect.ac = window?.amazon_connect?.ac || []).push(
          arguments
        )
      }
    const amazonConnect = window.amazon_connect
    amazonConnect('styles', {
      iconType: 'CHAT',
      openChat: { color: '#ffffff', backgroundColor: '#123456' },
      closeChat: { color: '#ffffff', backgroundColor: '#123456' },
    })
    amazonConnect('snippetId', snippedId)
    amazonConnect('supportedMessagingContentTypes', [
      'text/plain',
      'text/markdown',
      'application/vnd.amazonaws.connect.message.interactive',
      'application/vnd.amazonaws.connect.message.interactive.response',
    ])
    amazonConnect('customStyles', {
      transcript: { customerChatBubbleColor: '#BBBBBB' },
    })
    amazonConnect('customizationObject', {
      header: {
        dropdown: true,
      },
      footer: {
        disabled: false,
      },
    })

    chatHead.addEventListener('load', quiqLoadHandler)
    amazonConnect('contactAttributes', {
      tenant: api.config.TENANT.toUpperCase(),
    })
    document.head.append(chatHead)

    return () => {
      chatHead.removeEventListener('load', quiqLoadHandler)
    }
    //eslint-disable-next-line
  }, [type])
  return null
}
