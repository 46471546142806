import React, { JSX } from 'react'
import { Link } from 'react-router-dom'
import { IconDataTransferBoth } from '@ftdr/blueprint-components-react'
import { useSelector } from 'react-redux'
import { AppState } from 'src/utils/shared-types'

const CompareMobileButton = (): JSX.Element | null => {
  const { data: compareProducts, replacementId } = useSelector(
    (state: AppState) => state.compareProducts
  )
  const onDisabledClick = (e: any) => {
    if (compareProducts.length < 2) e.preventDefault()
  }
  return compareProducts.length > 0 ? (
    <Link
      id="compare-mobile-button-link-m"
      to={replacementId ? `/${replacementId}/compare` : '/compare'}
      className="mr-4 block lg:hidden"
      onClick={onDisabledClick}
    >
      <div className="flex items-center p-1">
        <div
          id="compare-mobile-button-icon-m"
          style={{ transform: 'rotate(90deg)' }}
        >
          <IconDataTransferBoth color={'interactive'} size="22" />
        </div>
        <div
          id="compare-mobile-button-count-m"
          className="ml-0.5  text-interactive"
          style={{ fontSize: 12 }}
        >
          {compareProducts.length}
        </div>
      </div>
    </Link>
  ) : null
}

export default CompareMobileButton
