import { Dialog, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button } from 'src/components/custom-fdr-components'
import React, { useState } from 'react'
import { MenuItem } from 'src/utils/shared-types'
import AddFeeForm from '../add-fee-form/add-fee-form'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'

interface IProps {
  propTenant?: MenuItem
  vendor?: string
}
const AddFee: React.FC<IProps> = ({ propTenant, vendor }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { tenant } = useShoppingCartContext()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        id="add-fee-button"
        label={localizedText('BUTTON_ADD_FEE')}
        className="custom-button w-full md:w-auto flex justify-center lg:inline-block h-9"
        disabled={!vendor || !tenant}
        onClick={() => setIsModalOpen(true)}
        style={{ lineHeight: 1 }}
      />

      <Dialog
        id="add-fee-dialog"
        open={isModalOpen}
        modal={true}
        onClose={() => setIsModalOpen(false)}
        className=""
        usePortal={true}
      >
        <AddFeeForm
          tenant={propTenant?.id || tenant}
          onClose={() => setIsModalOpen(false)}
        />
      </Dialog>
    </>
  )
}

export default AddFee
