import { emptyItem } from 'src/components/adminForms/initials'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { TInfo } from 'src/store/survey-store'
import { states } from 'src/utils/states'

interface UserDetails {
  firstName?: string
  lastName?: string
  contractID?: string
  billingState?: string
}
export const formatItemsToShoppingCartData = (data: appliancepb.ICart) => {
  if (!data.cartItems) return
  return data.cartItems.map(
    ({
      sku,
      vendor,
      category,
      subcategory,
      description,
      quantity,
      unitPrice,
      unitTax,
      originalPrice,
      isAvailableForZip,
      overrideReason,
    }) => {
      const total = Number(unitPrice) + Number(unitTax)
      const original = originalPrice ? Number(originalPrice) : Number(unitPrice)
      return {
        item: {
          sku,
          vendor,
          category,
          subcategory,
          description,
          isAvailableForZip,
          quantity,
        },
        quantity,

        unitTax,
        total,
        button: {
          label: total >= 0 ? 'Adjust Price' : 'Reapply',
          currentID: sku,
        },
        inputData: { quantity, sku },
        isAvailableForZip,
        price: {
          originalPrice: original,
          overrideReason,
          unitPrice,
        },
      }
    }
  )
}

export const createInitialValues = <T>(info: TInfo, values: T): T => {
  if (!info) {
    return values
  } else {
    return {
      ...values,
      firstName: info.customerName[0],
      lastName: info.customerName[1],
      contractID: info.contractId,
      dispatchID: info.dispatchId,
      billingState:
        states.find(({ id }) => id === info.billingState) || emptyItem,
    }
  }
}

export const updateFields = (
  info: TInfo,
  counter: number,
  setValue: <T, U>(field: T, v: U) => void,
  user: UserDetails
) => {
  const { firstName, lastName, contractID, billingState } = user

  if (counter === 0) {
    !firstName &&
      info?.customerName[0] &&
      setValue('firstName', info?.customerName[0])

    !lastName &&
      info?.customerName[1] &&
      setValue('lastName', info.customerName[1])

    !contractID && info?.contractId && setValue('contractID', info.contractId)
    !billingState &&
      info?.billingState &&
      setValue(
        'billingState',
        states.find((state) => state.id === billingState) || emptyItem
      )
  }
}

export const compareEqual1 = (v1: string, v2: string) => {
  if (v1 === '') {
    return true
  } else {
    return v1 !== v2
  }
}

export const compareEqual = (v1: string, v2: string) => {
  if (v1 === '') {
    return false
  } else {
    return v1 === v2
  }
}

export const genereteServiceFeePriceCode = (v: number) => {
  if (v < 100 || v >= 100000) {
    return
  }
  let price = String(v / 100)
  const buildStringCode = (value: string): string => {
    if (value.length < 3) {
      value = `${0}${value}`
      return buildStringCode(value)
    } else {
      return value
    }
  }
  return buildStringCode(price)
}
